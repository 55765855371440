<template>
	<main class="page-container container">
		<div class="section__top mb-30">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 20 : isMobile ? 24 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("news") }}
				</AppText>
			</div>
		</div>

		<div class="section__body mb-80">
			<div class="news__holder" :class="isMobileSmall ? 'mb-20' : 'mb-50'">
				<div class="news__item mb-30" v-for="(item, idx) in data" :key="idx">
					<div class="news__photo mr-30">
						<template v-if="item.type === 1">
							<img :src="getImgUrl(item.photos)" alt="no image" />
						</template>
						<template v-else>
							<img class="news__photo_exact" src="../../assets/icons/Notification.svg" alt="no image" />
						</template>
					</div>

					<div class="news__content">
						<AppText :size="isMobileMedium ? '15' : '18'" line-height="21" weight="700" class="mb-15">
							{{ item.type === 2 ? $t("notificationText") : item.mavzu[`${$store.state.clng}`] }}
						</AppText>

						<AppText size="14" line-height="18" class="mb-15 color-text">
							<template v-if="item.type === 2">
								<div v-html="item.qisqachaText[`${$store.state.clng}`]"></div>
							</template>
							<template v-else="">
								{{ item.qisqachaText[`${$store.state.clng}`] }}
							</template>
						</AppText>

						<BlockWrap width-auto offset-y="15" offset-x="20">
							<AppText size="14" line-height="18" weight="500" class="color-text-2"
								>{{ $moment(new Date(item.newsDate)).format("DD.MM.YYYY") }}
							</AppText>

							<template v-if="item.type === 1">
								<router-link :to="`/news/${item.id}`" class="news__link"
									>{{ $t("learnMore") }} >>
								</router-link>
							</template>
						</BlockWrap>
					</div>
				</div>
			</div>

			<pagination :total="this.total" :perPage="this.query.take" @paginate="pagination($event)"> </pagination>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import BlockWrap from "../../components/shared-components/BlockWrap";
import Pagination from "../../components/shared-components/Pagination";

export default {
	name: "index",
	components: {
		Pagination,
		BlockWrap,
		AppText,
	},
	data() {
		return {
			query: {
				skip: 0,
				take: 10,
			},
			total: 0,
			data: null,
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	methods: {
		load() {
			this.$api
				.get(`/api-client/Client/GetAllNews`, {
					params: this.query,
				})
				.then(
					(response) => {
						const res = response.data;
						if (res && res.result) {
							this.data = res.result.data;
							this.total = res.result.total;
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		getImgUrl(imgUrl) {
			if (imgUrl != null) {
				let separator = imgUrl.includes(";") ? ";" : "|";
				let img = Array.isArray(imgUrl.split(separator)[0])
					? imgUrl.split(separator)[0][this.activeImg]
					: imgUrl.split(separator)[0];
				return this.adminUrl + img.replaceAll(separator, "").replaceAll("null", "");
			} else {
				return imgUrl;
			}
		},
		pagination(skip) {
			this.query.skip = skip - this.query.take;
			this.load();
		},
	},
	mounted() {
		this.load();
	},
};
</script>

<style lang="scss" scoped>
.news {
	&__holder {
	}

	&__item {
		display: flex;
		width: 100%;
		transition: 0.5s;
	}

	&__photo {
		min-width: 208px;
		height: 138px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		overflow: hidden;

		img {
			max-height: 138px;
			height: 100%;
			max-width: 208px;
			width: 100%;
			object-fit: cover;
			transition: 0.5s;
		}
		.news__photo_exact {
			width: auto;
			height: 80px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&__content {
		flex-grow: 1;
	}

	&__link {
		color: #38b1f5;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		position: relative;
		transition: 0.5s;

		&::before {
			content: "";
			position: absolute;
			height: 2px;
			width: 0;
			background-color: #38b1f5;
			transition: 0.5s;
			top: calc(100% + 2px);
		}

		&:hover {
			&::before {
				width: 100%;
			}
		}
	}
}

@media (max-width: 768px) {
	.news {
		&__item {
			flex-wrap: wrap;
		}

		&__photo {
			margin-bottom: 20px;
		}
	}
}

@media (max-width: 450px) {
	.news {
		&__photo {
			max-width: 100%;
			width: 100%;
			max-height: 500px;
			position: relative;
			min-width: unset;
			margin-right: 0;

			img {
				width: 100%;
				border-radius: 6px;
				height: 100%;
			}
		}
	}
}
</style>

